const getAddressStructure = ({ property }) => {
  const hasStreet = property?.property_street || false;
  const hasPlzAndCity = (property?.property_zipcode && property?.property_city) || false;

  return (
    <>
      {
        (!hasStreet && !hasPlzAndCity)
          ? (
            <>
              <span>Liegenschaft hat keine postalische Addresse</span>

              <br />

              <span>&nbsp;</span>
            </>
          )
          : (
            <>
              <span >
                {
                  (property.property_street)
                    ? `${property.property_street} ${property.property_house_number ? property.property_house_number : ""}`
                    : "Liegenschaft hat keine postalische Addresse"
                }
              </span>

              <br />

              <span >{property.property_state}, </span>

              <span >
                {property.property_zipcode}

                {" "}

                {property.property_city?.replace(",", ", ")}
              </span>
            </>
          )

      }
    </>
  );
};

const PropertyAddress = ({ property }) => (
  <dd className="mt-1 text-gray-900 sm:mt-0">
    {getAddressStructure({
      property
    })}
  </dd>
);

export default PropertyAddress;

import cn from "classnames";

import dateFormatter from "~/src/modules/date-formatter.js";
import { formatCurrencyInt } from "~/src/modules/formatters.js";

const label = {
  BEFORE: "VORHER",
  DELETED: "GELÖSCHT",
  NEW: "NEU",
  REMAIN: "UNVERÄNDERT",
  UPDATED: "ÄNDERUNG"
};

const getStatusLabel = (type, personType) => {
  if (personType === 0 && type === "UPDATED") {
    return label.BEFORE;
  }

  return label[type] || "";
};

const getStatusClass = (type, personType) => cn("inline-block rounded uppercase font-semibold px-2", {
  "bg-amber-200 text-amber-800": personType === 1 && type === "UPDATED",
  "bg-emerald-200 text-emerald-800": type === "NEW",
  "bg-gray-300 text-gray-800": type === "REMAIN",
  "bg-red-200 text-red-800": type === "DELETED",
  "text-gray-700": personType === 0 && type === "UPDATED"
});

const getChangeClass = (changedKeys, keys, personType) => cn({
  "font-bold": keys.some((key) => changedKeys.includes(key)),
  "text-emerald-600": keys.some((key) => changedKeys.includes(key)) && personType === 1,
  "text-red-600": keys.some((key) => changedKeys.includes(key)) && personType === 0
});

const ShareDealOwnerCard = ({ person: [personNumber, content] }) => {
  const {
    changedKeys, personRole, persons, type
  } = content;

  return (
    <div className="flex w-full flex-col bg-gray-100 p-4 text-sm text-gray-800 sm:rounded-md">
      {persons.map((person) => (
        <div
          className="flex flex-col gap-y-2 border-t border-gray-300 py-4 first:border-t-0 first:pt-0 last:pb-0"
          key={`company_person_${person.t}`}
        >
          <div className="grid grid-cols-8 gap-y-2">
            <div className="col-span-2">
              <p className={getStatusClass(type, person.t)}>
                {getStatusLabel(type, person.t)}
              </p>
            </div>

            <div className="col-span-5 truncate text-gray-800">{personRole}</div>

            <div className="text-right">
              <span className="rounded bg-gray-300 px-2 py-0.5 font-semibold text-gray-700">
                {personNumber}
              </span>
            </div>
          </div>

          <div className="grid grid-cols-8">
            <div className="col-span-2 pl-2">
              <p>
                Firmenanteil:
                {" "}

                {person.company_shares
                  ? (
                    <span className={getChangeClass(changedKeys, ["company_shares"], person.t)}>
                      {(person.company_shares * 100).toLocaleString("de", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}

                      {" "}
                      %
                    </span>
                  )
                  : (
                    "-"
                  )}
              </p>

              <p
                className={getChangeClass(
                  changedKeys,
                  ["company_person_capital", "company_person_capital_currency"],
                  person.t
                )}
              >
                {person.company_person_capital
                  ? formatCurrencyInt({
                    currency: person.company_person_capital_currency,
                    val: person.company_person_capital
                  })
                  : "€ -"}
              </p>
            </div>

            <div className="col-span-5">
              <p className={getChangeClass(changedKeys, ["company_person_name "], person.t)}>
                {person.company_person_name || "-"}
              </p>

              {person.company_person_fbnr
                ? (
                  <p className={getChangeClass(changedKeys, ["company_person_fbnr"], person.t)}>
                    {person.company_person_fbnr}
                  </p>
                )
                : (
                  <p className={getChangeClass(changedKeys, ["company_person_birthdate"], person.t)}>
                    {person.company_person_birthdate
                      ? dateFormatter(person.company_person_birthdate, "day")
                      : null}
                  </p>
                )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ShareDealOwnerCard;

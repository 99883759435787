const getAddressStructure = ({ property }) => {
  const hasStreet = property?.property_street || false;
  const hasPlzAndCity = (property?.property_zipcode && property?.property_city) || false;

  return (
    <>
      {
        (!hasStreet && !hasPlzAndCity)
          ? null
          : (
            <>
              <span >
                {
                  (property.property_street)
                    ? `${property.property_street} ${property.property_house_number ? property.property_house_number : ""}`
                    : null
                }
              </span>

              <br />

              <span >{property.property_state}, </span>

              <span >
                {property.property_zipcode}

                {" "}

                {property.property_city?.replace(",", ", ")}
              </span>
            </>
          )

      }
    </>
  );
};

const PropertyAddress = ({ property }) => (
  <h3 className="text-2xl font-semibold text-gray-700">
    {getAddressStructure({
      property
    })}
  </h3>
);

export default PropertyAddress;

import { formatAreaInt } from "~/src/modules/formatters.js";
import { propertyTypeLabels } from "~/src/modules/labels.js";

import PropertyAddress from "./property-address.jsx";

const renderPropertyTypes = (propertyAttributes) => {
  const propertyElements = Object.entries(propertyAttributes)
    .map(([attributeKey, attributeValue]) => {
      if (attributeValue === null) {
        return null;
      }

      const attributeType = attributeKey.replace("property_building_type", "");

      return (
        <p key={`property_type_${attributeType}`}>{attributeValue} x {propertyTypeLabels[`type_${attributeType}`]}</p>
      );
    })
    .filter(Boolean);

  return (propertyElements.length > 0)
    ? propertyElements
    : <p>keine Angabe</p>;
};

const ShareDealPropertyCard = ({ property }) => {
  const {
    ez,
    kgnr,
    owner_fbnr,
    owner_name,
    owner_shares,
    property_area,
    property_city,
    property_gst_numbers,
    property_house_number,
    property_state,
    property_street,
    property_zipcode,
    ...propertyAttributes
  } = property;

  return (
    <div className="overflow-hidden bg-gray-100 p-3 text-sm text-gray-800 sm:rounded-md">

      <div className="flex justify-between">

        <div className="w-1/2">

          <PropertyAddress
            property={{
              property_city,
              property_house_number,
              property_state,
              property_street,
              property_zipcode
            }}
          />

          {/* <dd className="mt-1 text-gray-900 sm:mt-0">{property_street} {property_house_number}<br />{property_state ? property_state.replace(" ", "") : null}, {property_zipcode} {property_city ? property_city.replace(",", ", ") : null}</dd> */}

          <div className="mt-3">
            <dt className="font-semibold text-gray-800">Gebäudeart</dt>

            <dd className="mt-1 text-gray-900 sm:mt-0">{renderPropertyTypes(propertyAttributes)}</dd>
          </div>
        </div>

        <div className="w-1/2">

          <div className="flex justify-between">
            <div className="w-32">
              <dt className="font-semibold text-gray-800">KG</dt>

              <dd className="mt-1 text-gray-900 sm:mt-0">{kgnr}</dd>
            </div>

            <div className="w-32">
              <dt className="font-semibold text-gray-800">EZ</dt>

              <dd className="mt-1 text-gray-900 sm:mt-0">{ez}</dd>
            </div>

            <div className="w-48">
              <dt className="font-semibold text-gray-800">Fläche EZ</dt>

              <dd className="mt-1 text-gray-900 sm:mt-0">{formatAreaInt(property_area)}</dd>
            </div>
          </div>

          <div className="mt-3">
            <dt className="font-semibold text-gray-800">GST-Nr.</dt>

            <dd className="mt-1 text-gray-900 sm:mt-0">{Array.isArray(property_gst_numbers) ? property_gst_numbers?.join("; ") : property_gst_numbers}</dd>
          </div>

        </div>
      </div>

    </div>
  );
};

export default ShareDealPropertyCard;

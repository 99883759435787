import {
  Dialog, DialogBackdrop, DialogPanel, TransitionChild
} from "@headlessui/react";
import { useState } from "react";

import {
  formatAreaInt, formatCurrency, formatDate, formatMonth
} from "~/src/modules/formatters";
import { propertyTypeLabels } from "~/src/modules/labels";

import FeedbackModal from "~/src/features/feedback-modal";

import useShareDealPropertyDetail from "~/src/hooks/use-share-deal-property-detail.js";

import CloseIcon from "~/src/ui/icons/close-icon";

import ShareDealOwnerCard from "../share-deal-owner-card/index.jsx";

import PropertyAddress from "./property-address.jsx";

const renderDateList = (dateList) => dateList?.split(", ").map((date) => formatDate({
  date,
  withTime: false
})).join(", ");

const renderPropertyTypes = (propertyAttributes) => {
  const propertyElements = Object.entries(propertyAttributes)
    .map(([attributeKey, attributeValue]) => {
      if (attributeValue === null) {
        return null;
      }

      const attributeType = attributeKey.replace("property_building_type", "");

      return (
        <p key={`property_type_${attributeType}`}>{attributeValue} x {propertyTypeLabels[`type_${attributeType}`]}</p>
      );
    })
    .filter(Boolean);

  return (propertyElements.length > 0)
    ? propertyElements
    : <p>keine Angabe</p>;
};

const parseMainData = (main = {}) => ({
  propertyOwner: {
    owner_address: main.owner_address ?? null,
    owner_fbnr: main.owner_fbnr ?? null,
    owner_name: main.owner_name ?? null,
    owner_shares1: main.owner_shares1 ?? null,
    owner_shares2: main.owner_shares2 ?? null
  },
  propertySettings: {
    category_name: main.category_name ?? null,
    change_date: main.date_list ?? null,
    ez: main.ez,
    ez_area: main.property_area,
    gst_numbers: main.property_gst_numbers,
    kgnr: main.kgnr,
    propertyAttributes: {
      property_building_type1: main.property_building_type1,
      property_building_type2: main.property_building_type2,
      property_building_type3: main.property_building_type3,
      property_building_type4: main.property_building_type4,
      property_building_type5: main.property_building_type5,
      property_building_type6: main.property_building_type6,
      property_building_type7: main.property_building_type7,
      property_building_type8: main.property_building_type8,
      property_building_type9: main.property_building_type9
    }
  },
  register: {
    capital_currency: main.company_capital_currency ?? null,
    company_capital: main.company_capital ?? null,
    company_city: main.company_city ?? null,
    company_fbnr: main.company_fbnr ?? null,
    company_name: main.company_name ?? null,
    company_shares: main.company_shares ?? null,
    company_street: main.company_street ?? null,
    company_zipcode: main.company_zipcode ?? null
  }
});

/**
 *
 * @param {object} props - The root object
 * @param {boolean} props.active - The root object
 * @param {Function} props.hide - The root object
 * @param {object} props.property - The root object
 * @example
 */
export default function ShareDealPropertySlide({
  active, hide, property
}) {
  const [showFeedback, setShowFeedback] = useState(false);

  const {
    content: {
      main,
      parsedPersonChanges,
      properties: propertyNew
    } = {},
    isLoading
  } = useShareDealPropertyDetail(property);

  const {
    propertyOwner,
    propertySettings,
    register
  } = parseMainData(main);

  /**
   *
   * @example
   */
  function handleHide() {
    hide();
  }

  if (isLoading) {
    return (
      <Dialog
        className="fixed inset-0 z-[150] overflow-hidden"
        onClose={handleHide}
        open={active}
      >
        <FeedbackModal
          close={() => setShowFeedback(false)}
          entity_id={property?.id}
          feedback_type="share-deal-property"
          feedback_type_label={`${formatMonth(property?.transaction?.change_date)} - ${property?.transaction?.company_fbnr}`}
          show={showFeedback}
        />

        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-200 ease-in-out data-[closed]:opacity-0"
        />
      </Dialog>
    );
  }

  return (
    <Dialog
      className="fixed inset-0 z-[150] overflow-hidden"
      onClose={handleHide}
      open={active}
    >
      <FeedbackModal
        close={() => setShowFeedback(false)}
        entity_id={property?.id}
        feedback_type="share-deal-property"
        feedback_type_label={`${formatMonth(property?.transaction?.change_date)} - ${property?.transaction?.company_fbnr}`}
        show={showFeedback}
      />

      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-200 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <DialogPanel
            transition
            className="pointer-events-auto relative w-screen max-w-4xl transition duration-200 ease-in-out data-[closed]:translate-x-full sm:duration-200"
          >
            <TransitionChild>
              <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-200 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                <button
                  className="rounded-md text-white hover:text-gray-300 focus:outline-none focus:ring-0 focus:ring-white"
                  onClick={handleHide}
                >
                  <span className="sr-only">Schließen</span>

                  <CloseIcon aria-hidden="true" className="size-6" />
                </button>
              </div>
            </TransitionChild>

            <div className="flex h-full flex-col overflow-y-scroll bg-white pb-6 shadow-xl">

              <div className="flex h-16 shrink-0 items-center justify-between bg-primary px-4 sm:px-12">
                <h2 className="text-lg font-medium text-white" id="slide-over-heading">
                  Details zur Liegenschaft
                </h2>
              </div>

              <div className="mt-4 flex-1">

                <dl className="px-4 sm:px-8">

                  <div className="flex items-center justify-between px-4">
                    <PropertyAddress property={property} />

                    <button
                      className="rounded border border-primary bg-primary px-3 py-1 text-sm text-white hover:bg-primary-lighter focus:outline-none"
                      onClick={() => setShowFeedback(true)}
                      type="button"
                    >
                      Feedback
                    </button>
                  </div>

                  <div>

                    <div className="mx-4 mt-4 px-4 py-2">

                      <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                        <dt className="font-semibold text-gray-800">Eigentümer (Liegenschaft)</dt>

                        <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">
                          {propertyOwner.owner_name}
                        </dd>
                      </div>

                      <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                        <dt className="font-semibold text-gray-800">Gebäudeart</dt>

                        <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">
                          {renderPropertyTypes(propertySettings.propertyAttributes) || "-"}
                        </dd>
                      </div>

                    </div>

                    <div className="mx-4 px-4 py-2">
                      <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                        <dt className="font-semibold text-gray-800">KG</dt>

                        <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{propertySettings.kgnr}</dd>
                      </div>

                      <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                        <dt className="font-semibold text-gray-800">EZ</dt>

                        <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{propertySettings.ez}</dd>
                      </div>

                      <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                        <dt className="font-semibold text-gray-800">EZ Fläche</dt>

                        <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{formatAreaInt(propertySettings.ez_area)}</dd>
                      </div>

                      <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                        <dt className="font-semibold text-gray-800">GST-Nr.</dt>

                        <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{propertySettings.gst_numbers}</dd>
                      </div>
                    </div>

                    <div className="mb-4 mt-10">

                      <div className="mt-6">

                        <p className="ml-4 text-xl">Beteiligte Gesellschaft</p>

                        <div className="mx-4 mt-2 px-4 py-2">
                          <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Firmenname</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{register.company_name}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">FB-Nr.</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{register.company_fbnr}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Straße</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{register.company_street}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Ort</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{register.company_zipcode} {register.company_city}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Kapital</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">
                              {register.company_capital
                                ? formatCurrency({
                                  currency: register.capital_currency,
                                  val: register.company_capital
                                })
                                : "keine Angabe"}
                            </dd>
                          </div>

                          <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Änderungen am</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">
                              {renderDateList(propertySettings.change_date)}
                            </dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Art der Änderung</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{propertySettings.category_name}</dd>
                          </div>
                        </div>

                      </div>

                      <div className="mt-6">

                        <div className="flex items-end justify-between">
                          <p className="ml-4 text-xl">Personen- und Anteilsänderungen</p>

                          <p className="mr-4 text-right text-sm text-gray-800">PersNr.</p>
                        </div>

                        <div className="mx-4 mt-2 space-y-5 px-4 sm:px-0">
                          {Object.entries(parsedPersonChanges)?.map((person, index) => (
                            <ShareDealOwnerCard key={`person_${index}`} person={person} />
                          ))}
                        </div>
                      </div>

                    </div>

                  </div>

                </dl>

              </div>

            </div>

          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

import useSWR from "swr";

import { fetcher } from "../modules/api.js";

const useShareDealPropertyDetail = (parameters) => {
  const {
    change_date: changeDate,
    company_fbnr: companyFbnr,
    ez,
    kgnr
  } = parameters;

  const queryParameters = new URLSearchParams({
    changeDate,
    companyFbnr,
    ez,
    kgnr
  });

  const {
    data, error, mutate
  } = useSWR((changeDate && companyFbnr && ez && kgnr) ? `/share-deal-properties/detail?${queryParameters}` : null, fetcher);

  return {
    content: data?.payload ? { ...data.payload } : {},
    isError: error,
    isLoading: !error && !data,
    mutate
  };
};

export default useShareDealPropertyDetail;

import { UserGroupIcon } from "@heroicons/react/24/outline";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";

/**
 *
 * @param root0 - The root object
 * @param root0.onUpdate - The root object
 * @param root0.isTransaction - The root object
 * @param root0.typeIsTransaction - The root object
 * @example
 */
export default function ShareDealCompanySelect({ onUpdate, typeIsTransaction }) {
  const handleCreate = (value) => {
    onUpdate(value);
  };

  return (
    <CreatableSelect
      blurInputOnSelect
      isClearable
      className="react-select"
      formatCreateLabel={(userinput) => `Nach "${userinput}" suchen`}
      instanceId="share-dealcompany-select"
      name="share-deal-company"
      noOptionsMessage={noOptionsMessage}
      onCreateOption={handleCreate}
      styles={customStyles}
      value=""
      components={{
        Control,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Input
      }}
      inputProps={{
        autoComplete: "none",
        autoCorrect: "off",
        spellCheck: "off"
      }}
      placeholder={typeIsTransaction
        ? "Firma"
        : "Liegenschaftseigentümer"}
    />
  );
}

const noOptionsMessage = (data) => "Firmennamen oder FB-Nr. suchen...";

/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @example
 */
function Control({ children, ...rest }) {
  return (
    <components.Control {...rest} className="pl-2">
      <UserGroupIcon className="size-5 text-gray-400" /> {children}
    </components.Control>
  );
}

const Input = ({ ...rest }) => <components.Input {...rest} autoComplete="off" />;

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    minHeight: 44,
    "&:hover": {
      borderColor: state.isFocused ? "#9ca3af" : "#9ca3af"
    },
    borderColor: state.isFocused ? "#9ca3af" : "#e5e5e5",
    borderRadius: 2,
    borderWidth: state.isFocused ? "1px" : "1px",
    boxShadow: "state.isFocused ? \"0 0 0 20px rgba(56,178,172,1)\" : 0",
    height: 44
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: "#9ca3af",
    fontSize: "16px"
  }),

  singleValue: (base) => ({
    ...base,
    background: "#822c42",
    borderRadius: "5px",
    color: "white",
    padding: "0 6px"
  })
};
